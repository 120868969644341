<template>
  <div class="flex flex-col">
    <div v-if="withTitle" class="flex flex-row items-center">
      <span class="text-lg font-bold">Crear cliente</span>
      <div class="ml-auto">
        <sa-icon-button with-bg iconClass="text-xl bx bx-x" @click="cancel" />
      </div>
    </div>
    <div class="py-2">
      <el-form
        :model="client"
        :rules="formRules"
        ref="clientForm"
        v-loading="loadingSavingClient"
      >
        <el-form-item prop="fullName">
          <span>Nombre completo</span>
          <el-input v-model="client.fullName" />
        </el-form-item>
        <el-form-item prop="email">
          <span>Email</span>
          <el-input v-model="client.email" />
        </el-form-item>
        <el-form-item prop="phone">
          <span>Teléfono</span>
          <sa-phone-country :model="client" />
        </el-form-item>
        <el-form-item prop="contactType" v-if="false">
          <span>Medio de contacto</span>
          <el-select class="w-full" v-model="client.contactType">
            <el-option
              v-for="contactType in contactTypes"
              :key="contactType.id"
              :label="contactType.label"
              :value="contactType.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="contactDNI">
          <span>Numero de Identificación</span>
          <el-input v-model="client.dni" />
        </el-form-item>
        <el-form-item prop="contactDescription">
          <span>Dirección</span>
          <el-input type="textarea" v-model="client.contactDescription" />
        </el-form-item>
        <el-form-item prop="sendEmailNotifications" v-if="false">
          <el-checkbox v-model="client.sendEmailNotifications">
            Enviar notificaciones por email
          </el-checkbox>
        </el-form-item>
      </el-form>
    </div>
    <div v-if="errorMessage">
      <span class="text-red-500">{{ errorMessage }}</span>
    </div>
    <div class="flex justify-end flex-row space-x-4">
      <sa-button type="secondary" class="text-right" @click="cancel">
        Cancelar
      </sa-button>
      <sa-button
        type="primary"
        class="text-right"
        @click="save"
        v-loading="loadingSavingClient"
      >
        Guardar
      </sa-button>
    </div>
  </div>
</template>
<script>
import { ContactTypeEnum } from "@/constants";
import ClientService from "@/services/ClientService";
import error from "@/mixins/error";

export default {
  name: "ClientForm",
  props: {
    client: {
      type: Object,
      default: () => ({
        id: null,
        fullName: null,
        email: null,
        phone: null,
        phoneCountryCodeId: null,
        contactType: null,
        contactDescription: null,
        sendEmailNotifications: false,
        dni: null,
      }),
    },
    withTitle: {
      type: Boolean,
      default: () => false,
    },
  },
  mixins: [error],
  data() {
    return {
      formRules: {
        fullName: [
          { required: true, message: "Captura nombre y apellido del cliente" },
        ],
        phoneCountryCodeId: [{ required: true, message: "Captura el Código de Area del cliente" }],
        phone: [{ required: true, message: "Captura el teléfono del cliente" }],
        contactType: [
          { required: true, message: "Selecciona el medio de contacto" },
        ],
      },
      errorMessage: null,
      loadingSavingClient: false,
      contactTypes: [],
    };
  },
  created() {
    this.contactTypes = this.loadContactTypes();
    if (!this.client.phoneCountryCodeId) {
      this.client.phoneCountryCodeId = this.$store.state.business.phoneCountryCodeId || null;
    }
  },
  methods: {
    cancel() {
      this.$emit("onCancel");
    },
    async save() {
      this.$refs["clientForm"].validate(async (isFormValid) => {
        if (!isFormValid) return;
        await this.saveClient();
      });
    },
    async saveClient() {
      try {
        this.loadingSavingClient = true;
        const savedClient = !this.client.id
          ? await ClientService.create({
              ...this.client,
              contactType: ContactTypeEnum.Phone,
              email: this.client.email?.toLowerCase(),
              sendEmailNotifications: true,
            })
          : await ClientService.update(this.client.id, {
              ...this.client,
              contactType: ContactTypeEnum.Phone,
              email: this.client.email?.toLowerCase(),
              sendEmailNotifications: true,
            });
        this.$emit("onSaved", savedClient);
      } catch (error) {
        this.errorMessage = this.getErrorMessage(error);
        this.loadingSavingClient = false;
      }
    },
    loadContactTypes() {
      return [
        { id: ContactTypeEnum.Phone, label: "Teléfono" },
        { id: ContactTypeEnum.Whatsapp, label: "Whatsapp" },
        { id: ContactTypeEnum.Email, label: "Email" },
        { id: ContactTypeEnum.Other, label: "Otro" },
      ];
    },
  },
};
</script>
